import Header from "../Header/Header";
import axios from "axios";
import { HEADING_BASE_URL } from "../../config";

import "./Body.css";
import { Container, Row, Col } from "react-bootstrap";
import Loading from "../Loading/Loading";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebookF,
  faTwitter,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { useEffect, useState } from "react";
import CurrentCards from "../CurrentCards";
import FeaturedCards from "../FeaturedCards";

const Body = () => {
  const [text, setText] = useState([""]);
  const [loading, setLoading] = useState(true);

  const getData = () => {
    setLoading(true);
    axios
      .get(`${HEADING_BASE_URL}`)
      .then((response) => {
        let data = response.data;
        setText(data);
        setLoading(false);
      })
      .catch((error) =>
        console.error("The file you are trying to fetch doesn't exist")
      );
  };

  useEffect(() => getData(), []);

  const third_heading_text = text[0].third_header_text;
  // const fourth_heading_text = text[0].fourth_header_text;
  const fifth_hedaing_text = text[0].fifth_header_text;
  // let splitted_text = fourth_heading_text?.split("  ");



  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Header />
      <Container className="mt-5">
        <Row className="first-row">
          <div data-aos="fade-in" data-aos-duration="1000" data-aos-delay="200">
            <h3 className="text-center mt-5 mb-5">{third_heading_text}</h3>
          </div>
          <CurrentCards />
        </Row>
        <FeaturedCards />
        {/* <Row className="second-first-row"> */}
        {/* <div data-aos="fade-in" data-aos-duration="1000" data-aos-delay="200">
            {splitted_text.map((text, index) => {
              return (
                <h3 className="text-center" key={index}>{text}</h3>
              )
            })}
          </div> */}
        {/* </Row> */}
        <Row className="second-row mt-5 mb-5">
          <Col lg={12} md={12} sm={12} className="second-col">
            <div
              data-aos="fade-in"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <h3>{fifth_hedaing_text}</h3>
            </div>

            <div
              className="mail"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <a href="mailto:hello@moneta.al" className="mail-btn">
                hello@moneta.al
              </a>
            </div>
            <div
              className="social-icons mt-5 mb-5"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <a
                href="https://www.instagram.com/moneta_al/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                href="https://www.facebook.com/appmoneta"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a
                href="https://twitter.com/moneta_al"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a
                href="https://www.linkedin.com/company/monetaapplication/about/?viewAsMember=true"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Body;
