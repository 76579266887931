import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="d-flex flex-column my-4 p-5">
      <Link to="/" style={{ color: "#3E3DC5" }}>
        Back to Home
      </Link>
      <p className="p-2 text-center fs-3 fw-bolder mb-5">Privacy Policy</p>
      <div>
        <p>
          We conduct our application in compliance with relevant data protection
          legislation applicable to our application in Albania and have
          implemented additional measures to protect your personal information.
          This Privacy Policy applies to your use of any online services that
          posts a link to this Privacy Policy, regardless of how you access or
          use it. By visiting and otherwise using our Service, you consent to
          the Service’s{" "}
          <Link to="/terms-and-conditions">Terms & Conditions</Link>, which
          governs your use of the Service, and our Privacy Policy and any
          additional privacy statements that may be posted on an applicable part
          of the Service which provide information regarding our collection, use
          and sharing of your information and data, and other activities, as
          described below. If you do not agree or consent, please discontinue
          use of the Service, and uninstall Service downloads and applications.
          We recognise that you should have the right to know what data we
          collect about you, how we use the collected data and what controls you
          have over that data.
        </p>
      </div>
      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">
          What Information do we Collect and Process about You?
        </p>
        <p>
          How we use data is summarized on our How We Use Data information page.
          We collect receipt data and aggregate and anonymise this data with
          millions of other receipt data to facilitate consumer insights and
          market research. In order to develop and create these aggregated and
          anonymised data sets, we collect receipt data and related information
          from you. Before we anonymise and aggregate this data, such
          information is personal information for the purposes of applicable
          privacy laws.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">Receipt Data</p>
        <p>
          We collect receipt data and other information about your purchase
          history either through: the receipts that you directly upload to your
          Spendings section through mobile application. More detail regarding
          the personal information that we collect is set out below, including
          personal information that we may collect outside of the receipt data
          highlighted above. We may collect and process the following types of
          information:
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">Information You Provide</p>
        <p>
          Service Providers may collect information you provide directly to
          Spendings or Add Manually For example, as highlighted above, we
          receive and store the information you enter on mobile application, or
          you otherwise provide us in any other way (added manually) .
          Information may collect include: (1) personally identifiable
          information, which is information that identifies you personally, such
          as your first and last name and (2) demographic information, such as
          your gender, age.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">Connected Accounts</p>
        <p>
          As highlighted above, when using Moneta, you may elect to connect your
          Account to other online accounts, such as your email account or online
          marketplace accounts, including Google and Gmail. You can manage
          whether your Moneta Account is connected with these third-party
          accounts through our platform, or you can seek help by contacting us
          at <Link to="mailto:hello@moneta.al">hello@moneta.al</Link> any time,
          or via the Help Centre.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">Third-Party Platform Logins</p>
        <p>
          Moneta allows you to create a new Account with us, and sign in, using
          your Google or Apple account. If you create an Account using these
          third-party providers, those platforms will get your permission to
          share certain information from your Google or Apple account (as
          applicable) with us. This could include your first name, email
          address. This information is collected by those third parties and is
          provided to us under the terms of each entity’s Privacy Policy. You
          can manage the information that we receive from these platforms using
          the privacy and data settings in your Google or Apple account (as
          applicable).
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">Scans</p>
        <p>
          If you decide to submit your online receipts to Moneta. We will obtain
          information from the scanning receipts you forwarded to us regarding
          your online purchases including details of what was purchased, the
          vendor information, the total amount spent and the purchase date &
          time. We attempt to use machine protocols to extract personal
          information provided by our users at all times in order to limit the
          number of individuals within our organization who have access to
          personal information.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">Third-Party Data</p>
        <p>
          Information about you from third party connected-accounts that is
          publicly available, such as personal information and estimated
          demographic information. If you connect your Account to the third
          party and grant the Site permission to access this information, you
          provide your consent to collect this information from these sources.
          We will collect receipt Information from the merchants in the cases
          where you have provided us with access to an online account. We may
          receive information about you from third-party companies that
          specialize in collecting publicly available information. This may
          include estimated demographic information and information from public
          registers, such as the electoral register. When you register for an
          Account, you consent to the collection of such publicly available
          information concerning you. We may receive information about you from
          other sources. This may include demographic information from data
          collection companies and others in order for us to offer anonymous
          market research reports to clients and partners. Any such information
          obtained from third party sources will be used in accordance with the
          terms and on the basis that that information is provided to us.
          Notwithstanding anything to the contrary, except to the extent such
          data is combined by or on behalf of Moneta with personal information
          that we have otherwise collected, this Privacy Policy is not intended
          to limit Moneta’s activities regarding such third-party-sourced, or
          non-Service-sourced information. Except as otherwise required by law,
          Moneta is not responsible or liable for the accuracy of the
          information provided by third parties or for third party policies or
          practices. We may link information about you that we collect from
          these third parties with other information that we collect about you.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">Device Recognition Technologies</p>
        <p>
          Technologies, including application of statistical probability to data
          sets, as well as linking a common unique identifier to different
          device use , which attempt to recognize or make assumptions about
          users and devices (e.g., that a user of multiple devices is the same
          user or household) (“Cross-device Data”).
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">Right to withdraw consent</p>
        <p>
          To the extent that your consent is required for us to collect, use or
          disclose your personal information in accordance with this Privacy
          Policy and under applicable privacy law, you may withdraw your consent
          to certain uses or disclosures of your personal information.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">Device and Activity Monitoring</p>
        <p>
          Technologies that monitor, and may record, certain of your
          interactions with the Service, and/or collect and analyze information
          from your device, such as, without limitation, your operating system,
          plug-ins, system fonts, and other data, for purposes such as
          identification, security, fraud prevention, troubleshooting, tracking
          and/or improving the Services and customizing or optimizing your
          experience on the Services. This may also include technologies that
          monitor and record usage sessions to help us better understand, track,
          and improve the usage of our Services.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">Log and other information</p>
        <p>
          When you access our app our servers automatically record information
          that your browser sends during your access to that content. These
          server logs typically include your browser type, browser language, the
          date and time of your request and one or more cookies that may
          uniquely identify your browser or your Account to us. Other actions,
          such as interactions with advertisements, including the fact you have
          been shown certain advertisements, and the Advertising ID of your
          device may also be included in our server logs. Some information about
          your use of the Service and certain other online services may be
          collected using Tracking Technologies across time and services and
          used by Moneta and third parties for purposes such as to associate
          different devices you use and deliver relevant ads and/or other
          content to you on the Service and certain other online services.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">
          How do we collect yoru Personal Information?
        </p>
        <div>
          <p>
            How we collect your personal information is often dependent upon the
            type of information that we collect. As such, descriptions of how we
            collect your personal information are included on an
            information-type-by-information-type basis in the sections above.
            That being said, the sources of personal information that we collect
            can be generally summarized as follows:
          </p>
          <ul>
            <li>
              <p>
                directly from you when you use our App or other aspects of our
                platform or otherwise use your Account to provide information to
                us;{" "}
              </p>
            </li>
            <li>
              <p>
                from your connected accounts, such Google Gmail which you
                connect to your Account and allow us to access for the purpose
                of culminating receipt data;
              </p>
            </li>
            <li>
              <p>
                from your devices, through interaction between your device and
                our platform or third parties’ platforms and associated cookies;
              </p>
            </li>
            <li>
              <p>
                from third party service providers who provide services on our
                behalf;
              </p>
            </li>
            <li>
              <p>
                social networks or device platforms such as Google and Apple;{" "}
              </p>
            </li>
            <li>
              <p>online advertising services and advertising networks;</p>
            </li>
            <li>
              <p>operating systems and platforms; and</p>
            </li>
            <li>
              <p>data brokers or analytics providers.</p>
            </li>
          </ul>
        </div>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">HOW DO WE USE YOUR INFORMATION?</p>
        <p>
          Moneta may use information about you for any purpose not inconsistent
          with Moneta’s statements under this Privacy Policy, or otherwise made
          by us in writing at the point of collection, and not prohibited by
          applicable law. The following are examples of the primary ways in
          which we may use your personal information:
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">
          Enhance the services we provide you and contact you.
        </p>
        <p>
          We use your personal information to investigate complaints, claims,
          and disputes. We also use your personal information to improve site
          and service performance and the delivery of our services, such as when
          you make inquiries into and provide feedback on our quality of
          service. We may also use personal information relating to you to
          understand your preferences, develop features, or personalize and
          improve the products we offer you. We may also use this information to
          keep you updated of new features (subject to our compliance with
          applicable anti-spam laws). We will do so by sending you emails and
          sending you in-application push notifications.
        </p>
      </div>
      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">For market research purposes</p>
        <p>
          When you upload receipts to Moneta, we aggregate this receipt
          information with millions of other receipts and de-identify your
          receipts so that it does not contain any specific personally
          identifiable information. This aggregated information is then used to
          provide clients with anonymous market research reports, consumer
          insights and trends. Our users never see any single individual’s
          personally identifiable information. We may disclose the information
          we collect about you and your household to third parties to outsource
          certain tasks, such as to companies who complete more data analysis.
          In certain situations, data regarding your purchase habits and
          shopping preferences that you have provided to Moneta may be
          de-identified and provided to third parties for market research or
          data analytics purposes. While this data may not be aggregated, our
          systems de-identify this information to ensure that all personally
          identifying information is removed before it is provided to third
          parties. You consent to us disclosing your information in this manner
          by using our App or our platform, and we reward you for doing so
          through your participation in our various reward programs. Where we do
          provide your information to third parties we will always take
          reasonable steps to ensure that your privacy is protected, as further
          described below.
        </p>
      </div>
      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">
          Protect our rights or property or our Registered Users
        </p>
        <p>
          We also may use the information we collect to the extent we believe it
          is appropriate to enforce or apply our rights under our{" "}
          <Link to="/terms-and-conditions">Terms & Conditions</Link>, and/or to
          protect the rights, property or safety of Moneta , our registered
          users and others. This may include using personal information to
          detect security incidents and protect against malicious, deceptive,
          fraudulent or illegal activity and prosecuting such individuals at our
          discretion, debugging activities to identify and repair error in our
          services or platform and other internal research and verification
          activities.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">
          Fulfill legal and regulatory requirements
        </p>
        <p>
          We may use the information we collect to comply with federal, state,
          provincial or other applicable laws and regulations.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">Additional Uses</p>
        <div>
          <p>
            In addition to the uses set forth above, we may also use the
            information we collect to:
          </p>
          <ul>
            <li>
              <p>
                allow you to participate in features we offer on the Service;
              </p>
            </li>
            <li>
              <p>
                facilitate, manage, personalize, and improve your online
                experience;
              </p>
            </li>
            <li>
              <p>process your registration and manage your account.</p>
            </li>
            <li>
              <p>
                fulfill other purposes disclosed at the time you provide
                personal information or otherwise where we are legally permitted
                or are required to do so;
              </p>
            </li>
            <li>
              <p>
                prevent and address fraud, breach of policies or terms, and
                threats or harms.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">De-identified information</p>
        <p>
          Please note any personal information that we collect may be
          “de-identified”, such as through anonymisation, pseudonymisation,
          and/or hashing, is not considered personal information for the
          purposes of applicable privacy law and may be used and/or shared
          without obligation to you, except as prohibited by applicable law.
          Without limiting the generality of the foregoing, we and third parties
          may convert your personal information (including PII) to
          non-Personally Identifiable Information, including without limitation
          through hashing it or substituting a unique identifier for the PII and
          we and third parties may use and share that data as permitted by
          applicable law, including to match data attributes to and from other
          sources. Any such third-party activities are subject to their privacy
          policies and practices.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">HOW DO WE SHARE YOUR INFORMATION?</p>
        <p>
          We may share personal information that we collect about you with third
          parties for the purpose for which that personal information was
          collected, in connection with the uses of your personal information as
          described above and for certain secondary or related purposes as
          permitted by applicable privacy laws. In particular, we may share your
          personal information with third parties for the following purposes:
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">Marketing</p>
        <p>
          Subject to your communications choices, we may use your personal
          information to send you marketing communications. Absent your consent
          (which may be by means of opt-in, your election not to opt-out, or a
          third party interaction described below), however, Moneta will not
          share personal information that we collect directly from you with
          third parties, other than Moneta Affiliates, for their own direct
          marketing purposes.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">Contests, Monthly Draws and Surveys</p>
        <p>
          We may promote or offer contests, monthly draws, similar promotional
          offers or surveys that may be co-sponsored by us and one or more other
          companies, or may be sponsored by other companies. Some or all of your
          information collected through these offers or surveys may be shared
          with the sponsor(s) or other companies indicated on the entry form, in
          the governing rules or on the survey, as applicable. The collection,
          use and disclosure of your personal information by such sponsor(s) or
          other companies is subject to the privacy policies and practices of
          those third parties as indicated on the contest, draw or promotional
          offer entry form and we are not responsible or liable for any such
          collection, use or disclosure. By entering, you are agreeing to the
          official rules that govern that contest, monthly draw, and/or survey,
          which may include consent to additional or differing data practices
          from those contained in this Privacy Policy. Please review those rules
          carefully.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">SALE OF PERSONAL INFORMATION </p>
        <p>
          While we do not ‘sell’ your personal information to unrelated third
          parties, we do strategically engage with third party service providers
          and affiliates that we consider may align with our business practices
          or which may otherwise offer services that are beneficial to you.
          Under certain applicable privacy laws, these practices are considered
          to be the ‘sale’ of your personal information to third parties for
          monetary or other valuable consideration. Under this definition, we
          may sell your personal information in the following ways:
        </p>
        <ul>
          <li>
            <p>
              <b>(Market Data)</b> We may share data related to your purchasing
              habits, receipts or survey responses with clients or third parties
              for market research or data analytics purposes. The data we share
              with third parties in this manner is de-identified, such that it
              may contain information about your purchasing habits and responses
              but no information that is likely to personally identify you. Such
              information generally is not personal information for the purposes
              of applicable privacy laws, however there may be circumstances
              where such data inadvertently includes your personal information
              or is otherwise specifically considered to be personal information
              at law (despite that it does not otherwise identify you). As such,
              and to ensure your data privacy is taken seriously, we only share
              this data with your consent, which you provide when using our App
              or otherwise engage with our services. Common third parties with
              whom we provide market data.
            </p>
          </li>
          <li>
            <p>
              <b>(Advertising Data)</b> We may permit certain third party online
              advertising services to collect personal information about you
              through our App or on any of our platforms via automated
              technologies, such as cookies. These advertising providers may use
              personal information such as your online and device identifiers
              and your activity in connection with our services for online
              advertising purposes to place ads that are relevant or beneficial
              to you.
            </p>
          </li>
        </ul>
        <p>
          You have the right to opt out of these disclosures of your personal
          information as described further below.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">
          INFORMATION YOU DISCLOSE PUBLICLY OR TO OTHERS
        </p>
        <p>
          Third-Party Content, Third-Party Services, Social Features,
          Advertising and Analytics
        </p>
        <p>
          The Service may include or link to third-party websites, apps,
          locations, platforms, code. These Third-Party Services may use their
          own cookies, web beacons, and other Tracking Technology to
          independently collect information about you and may solicit personal
          information from you. Certain functionalities on the Service permit
          interactions that you initiate between the Service and certain
          Third-Party Services, such as third party social networks (“Social
          Features”). If you use Social Features, and potentially other
          Third-Party Services, information you post or provide access to may be
          publicly displayed on the Service or by the Third-Party Service that
          you use.. Also, both Moneta and the third party may have access to
          certain information about you and your use of the Service and any
          Third-Party Service.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">
          HOW DO WE SECURE, RETAIN AND LIMIT YOUR INFORMATION?
        </p>
      </div>
      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">Security of Your Account</p>
        <p>
          We will take reasonable steps to protect the personal information we
          collect from loss, misuse, unauthorized access, disclosure,
          alteration, and destruction. We have put in place appropriate
          physical, electronic and managerial procedures to safeguard and secure
          your personal information from misuse and unauthorized access,
          disclosure, alteration or destruction, however you are responsible for
          maintaining the secrecy of your unique password and Account
          information, and for controlling access to emails between you and
          Moneta, at all times. We are not responsible for the functionality,
          privacy, or security measures of any other organization.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">
          Where your information is stored or disclosed
        </p>
        <p>
          Moneta is based in Albania and the information Moneta and its Service
          Providers collect is governed by the laws of Albania. Moneta offers
          services only in Albania. Your use of the Service or provision of any
          information therefore constitutes your consent to the transfer to and
          from, processing, usage, sharing, and storage of your information,
          including personal information, to overseas recipients as set forth in
          this Privacy Policy.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">Retaining Your Personal Information</p>
        <p>
          We will only retain your personal information as long as is necessary
          for the fulfillment of the purposes for which it was collected or as
          required by law. Your personal information is securely stored until
          such time as it is no longer required, after which it is destroyed or
          rendered such that it is unable to identify you.
        </p>
      </div>
      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">
          YOUR RIGHTS UNDER APPLICABLE PRIVACY LAW
        </p>
        <p>
          Depending upon your jurisdiction and the jurisdictions in which we
          operate, you may have various rights under applicable privacy laws.
          Please note, to the extent your exercise of any rights provided below
          impacts our ability to provide Market Research and Rewards Programs,
          we reserve the right to cease offering certain aspects of our services
          to you at our discretion.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">Access</p>
        <p>
          You are entitled to access the personal information we hold about you
          and may request that we correct any errors in the information we hold.
          We will take reasonable steps to allow you to access your personal
          information unless reasonable circumstances exist that would prohibit
          us from doing so.
        </p>
      </div>
      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">Correction</p>
        <p>
          We will correct your personal information where we are satisfied that
          the information is inaccurate, out of date, incomplete, irrelevant or
          misleading. If we correct any personal information that we have
          disclosed to third parties we will take reasonable steps to notify
          those parties of the change or update.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">Deletion</p>
        <p>
          You have the right to request deletion of your personal information
          that we have collected about you, subject to certain exceptions.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">Right to Know</p>
        <p>
          You may request that we disclose to you what personal information we
          have specifically collected, used, shared or sold about you and why we
          have done so, subject to certain exceptions. While this Privacy Policy
          sets out these details on a general basis, you may request that we
          provide this information on a specific basis concerning your personal
          information.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">
          CHOICES: TRACKING AND COMMUNICATIONS OPTIONS
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">Tracking Technologies Generally</p>
        <p>
          Regular cookies may generally be disabled or removed by tools
          available as part of most commercial browsers, and in some instances
          blocked in the future by selecting certain settings. Browsers offer
          different functionalities and options, so you may need to set them
          separately. Also, tools from commercial browsers may not be effective
          with regard to HTML5 cookies, or other Tracking Technologies. Please
          be aware that if you disable or remove these technologies, some parts
          of the Service may not work and that when you revisit the Service your
          ability to limit browser-based Tracking Technologies is subject to
          your browser settings and limitations. Some App-related Tracking
          Technologies in connection with non-browser usage (e.g., most
          functionality of a mobile app) can only be disabled by uninstalling
          the app. To uninstall an app, follow the instructions from your
          operating system or handset manufacturer. Apple and Google mobile
          device settings have settings to limit ad tracking, and other
          tracking, but these may not be completely effective. We do not
          represent that these third-party tools, programs or statements are
          complete or accurate. You will need to do this on each browser that
          you use to access our Services, and clearing cookies on your
          browser(s) may disable your preference settings. Also, our Services
          may not function properly or as intended if you block all or even
          certain cookies.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">Communications</p>
        <p>
          Where we use your personal information to send you marketing and
          promotional information you will be provided with the opportunity to
          opt-out of receiving such information. Unless you exercise your right
          to opt-out of such communication, you will be taken to have consented
          to receive similar information and communications in the future. You
          can opt out of receiving certain promotional communications (emails or
          text messaging) from Moneta at any time by (i) for promotional emails,
          following the instructions provided in emails to click on the
          unsubscribe link, or if available by changing your communication
          preferences by logging onto your account; (ii) for text messages,
          following the instructions provided in text messages from Moneta to
          text the word, “STOP”; and (iii) for app push notifications turn off
          push notifications on the settings of your device and/or the app, as
          applicable. Please note that your opt-out is limited to the e-mail
          address or phone number used and will not affect subsequent
          subscriptions. If you opt-out of only certain communications, other
          subscription communications may continue. Even if you opt out of
          receiving promotional communications, Moneta may, subject to
          applicable law, continue to send you non-promotional communications,
          such as those about your account, transactions, servicing, or Moneta’s
          ongoing business relations.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">CHANGES TO THIS PRIVACY POLICY</p>
        <p>
          We may update this Privacy Policy from time to time in our sole
          discretion. If we make changes to this Privacy Policy, we will post
          the revised Privacy Policy on the App. Please be sure to periodically
          check this page to ensure that you are aware of any changes to this
          Privacy Policy. Any material changes to this Privacy Policy will be
          communicated to registered users by a notification to their Account.
          Your continued access to or use of the Platform after the effective
          date of such changes will be subject to the revised Privacy Policy.
          This Privacy Policy states, at the top of the page, the date on which
          it was last updated.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">Unsubscribe</p>
        <p>
          To unsubscribe from all future emails, click the unsubscribe link in
          the welcome email or any subsequent email from us. You may also email
          or write to us using the details set out below.
        </p>
      </div>

      <div className="d-flex flex-column my-2">
        <p className="fs-5 fw-bold mb-4">Contacting Us</p>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          including our use of service providers outside of your jurisdiction of
          residence, you can email our Privacy Officer at{" "}
          <Link to="mailto:hello@moneta.al">hello@moneta.al</Link>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
