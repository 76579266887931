import { useState, useEffect } from "react";
import axios from "axios";

import { Col } from "react-bootstrap";
import { FEATURED_CARDS_URL } from "../config";

const FeaturedCards = () => {
  let [featuredCardsData, setfeaturedCardsData] = useState([""]);

  const cardsData = () => {
    axios
      .get(`${FEATURED_CARDS_URL}`)
      .then((response) => {
        let data = response.data;
        setfeaturedCardsData(data);
      })
      .catch((error) =>
        console.error("The file you are trying to fetch doesn't exist!")
      );
  };

  useEffect(() => cardsData(), []);

  return (
    <>
      {featuredCardsData.map((cardData) => {
        return (
          <Col lg={4} md={12} sm={12} key={cardData.id + 2}>
            <div
              className="featured_card text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <div className="card_header mb-3">
                <img src={cardData.image} alt="invoices" />
              </div>
              <div className="card_body">
                <h4>{cardData.header}</h4>
                {cardData.description}
              </div>
            </div>
          </Col>
        );
      })}
    </>
  );
};

export default FeaturedCards;
