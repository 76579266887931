import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {

    const history = useNavigate();

    useEffect(() => {
       history("/")
    }, [])

    return (
        <div></div>
    )
}

export default NotFound