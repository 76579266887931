import Moneta from "../../assets/images/monetaLogo.png";
import "./Loading.css";
const Loading = () => {
  return (
    <div className="loadingAnimation">
      <div className="moneta-face">
        <img src={Moneta} alt="moneta" />
      </div>
    </div>
  );
};

export default Loading;
