import { useState, useEffect } from "react";
import axios from "axios";

import { Col } from "react-bootstrap";
import { CURRENTS_CARDS_URL } from "../config";

const CurrentCards = () => {
  let [cardDatas, setData] = useState([""]);

  const currentCardsData = () => {
    axios
      .get(`${CURRENTS_CARDS_URL}`)
      .then((response) => {
        let data = response.data;
        setData(data);
      })
      .catch((error) =>
        console.error("The file you are trying to fetch doesn't exist!")
      );
  };

  useEffect(() => currentCardsData(), []);

  return (
    <>
      {cardDatas.map((cardData) => {
        return (
          <Col lg={4} md={12} sm={12} key={cardData.id + 1}>
            <div
              className="featured_card text-center mb-5"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <div className="card_header mb-3">
                <img src={cardData.image} alt="scan" />
              </div>
              <div className="card_body">
                <h4>{cardData.header}</h4>
                <p>{cardData?.description?.slice(3,-4)}</p>
              </div>
            </div>
          </Col>
        );
      })}
    </>
  );
};

export default CurrentCards;
