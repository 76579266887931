import React, { useState } from 'react'

const Modal = ({ isOpen, setIsOpen }) => {

    if (isOpen) {
        document.body.classList.add("active-modal");
    } else {
        document.body.classList.remove("active-modal");
    }

    const handleExit = () => {
        setIsOpen((prev) => !prev);
    }

    return (
        <>
            {isOpen && (
                <div className="_modal">
                    <div style={{
                        backgroundColor: 'black',
                        opacity: 0.4,
                        position: 'fixed',
                        width: '100vw',
                        height: '100vh'
                    }}></div>
                    <div className="custom-modal-content mx-auto bg-white text-black">
                        <svg
                            id="Layer_1"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 412.83 99.46"
                        >
                            <path
                                className="cls-1"
                                d="M19.89,71.26a2.88,2.88,0,0,1-2.34-3.44l8.64-40.76a2.94,2.94,0,0,1,5.75,1.22L23.3,69A2.88,2.88,0,0,1,19.89,71.26Z"
                            />
                            <path
                                className="cls-1"
                                d="M46.76,70.64a2.88,2.88,0,0,1-3.65-2L32.19,28.45a2.94,2.94,0,0,1,5.67-1.54l10.92,40.2A2.87,2.87,0,0,1,46.76,70.64Z"
                            />
                            <path
                                className="cls-1"
                                d="M77.88,71.26a2.88,2.88,0,0,0,2.34-3.44L71.59,27.06a2.94,2.94,0,0,0-5.76,1.22L74.47,69A2.88,2.88,0,0,0,77.88,71.26Z"
                            />
                            <path
                                className="cls-1"
                                d="M51,70.64a2.88,2.88,0,0,0,3.65-2l10.92-40.2a2.94,2.94,0,0,0-5.67-1.54L49,67.11A2.88,2.88,0,0,0,51,70.64Z"
                            />
                            <path
                                className="cls-1"
                                d="M49,0c-65.37,1.12-65.36,98.35,0,99.46C114.39,98.34,114.38,1.11,49,0Zm0,93.7c-57.3-1-57.29-87,0-88C106.32,6.7,106.31,92.72,49,93.7Z"
                            />
                            <path
                                className="cls-1"
                                d="M174.17,54.07c0-9.55.11-19.43.34-25h-.29C171.94,38,164.1,59.41,158.3,75.82h-6.85C147.06,62.28,139,38.16,136.61,29h-.25c.41,6,.59,17.48.59,26.43V75.82h-7.24V23.6h11.56c5.58,15.72,12.31,35.91,14.06,42.77h.12c1.24-5.37,9.27-27.44,15.06-42.77h11.21V75.82h-7.55Z"
                            />
                            <path
                                className="cls-1"
                                d="M241.74,75.82V23.6h10.08c7.31,12.12,22.79,37.17,25.62,42.85h.11c-.52-6.46-.48-14.85-.48-23.39V23.6h7.24V75.82h-9.49c-6.65-11.12-22.95-38.37-26.09-44.26h-.11c.37,5.69.4,14.89.4,24.3v20Z"
                            />
                            <path
                                className="cls-1"
                                d="M324.17,51.77H298.39V69.26h27.06v6.56H290.82V23.6h34.57v6.56h-27V45.21h25.78Z"
                            />
                            <path
                                className="cls-1"
                                d="M348.06,30.16H331.39V23.6h41.14v6.56H355.85V75.82h-7.79Z"
                            />
                            <path
                                className="cls-1"
                                d="M393.61,23.6H384L365.58,75.82h7.69l7.23-21.54c3.82-11.3,6.78-20,8.06-24.57h.08c1.16,4.11,3.82,11.35,8.56,24.57l7.4,21.54h8.23Z"
                            />
                            <path
                                className="cls-1"
                                d="M211.73,23.92a25.79,25.79,0,1,0,25.79,25.79A25.79,25.79,0,0,0,211.73,23.92Zm0,44.71a18.92,18.92,0,1,1,18.92-18.92A18.92,18.92,0,0,1,211.73,68.63Z"
                            />
                        </svg>
                        <p className='text-lg'>We are currently working on this page!</p>
                        <button
                            className='btn btn-primary'
                            onClick={handleExit}>Ok</button>
                    </div>
                </div>
            )}
        </>
    );

}

export default Modal