import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const TermsAndConditions = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className='d-flex flex-column my-4 p-5'>
            <Link to="/" style={{ color: "#3E3DC5" }}>
                Back to Home
            </Link>
            <p className='p-2 text-center fs-3 fw-bolder mb-5'>Terms & Conditions</p>
            <div>
                <p>In this document, we provide our terms and conditions (Terms or Agreement) that you must read and accept before using, accessing, or interacting with the MONETA App (App).
                    <br />
                    <br />
                    The below Terms are effective from 1 June 2023.
                    <br />
                    Defined terms used in this Agreement have the meanings set out in the body of this document.
                    <br />
                    Please contact us at <Link to="mailto:hello@moneta.al">hello@moneta.al</Link>  if you have any questions or concerns.
                </p>
            </div>
            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>General</p>
                <p>
                    The MONETA APP (the App or the Service) is a product of Grid Coding sh.p.k , a company registered in Albania by VAT no: M120090005K  (“we” or “us”).
                    <br />
                    <br />
                    These Terms govern your use of Moneta App and provide information about Moneta App. Please read these Terms carefully before you download, install or use Moneta App. By accessing Moneta App, you agree to be bound by these Terms.
                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Acceptance of the Terms and Conditions</p>
                <p>
                    By downloading, installing, using or submitting a membership application to Moneta App, you indicate that you accept these Terms and that you agree to abide by them as well to comply with other terms, conditions, notices and disclaimers contained elsewhere on the App, Service or on our website. Your download, installation, use or subscription for membership of Moneta App constitutes your acceptance of these Terms which takes effect on the date on which you download, install, use or subscribe for membership with Moneta App.
                    <br />
                    <br />
                    If you do not agree with these Terms, you should cease downloading, installing or using Moneta App immediately.

                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Changes to the terms and conditions</p>
                <p>
                    Subject to any notice obligations under applicable law, we reserve the right to edit these Terms at any time upon provision of no less than 30 days’ notice to you by posting changes on https:moneta.al (Website) or by updating the App to incorporate the new terms of use. The version of Terms published on our Website from time to time is the current version and will bind us and all members and users.
                    <br />
                    <br />
                    We recommend that you check the current Terms before using the Service. If you do not agree to these Terms or any changes to them, you should immediately cease using the Service.
                    <br />
                    <br />
                    You are responsible for regularly reviewing information posted online to obtain timely notice of such changes. Your continued use of Moneta App after changes are posted constitutes your acceptance of the amended Terms.

                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Changes to the Service</p>
                <p>
                    To the extent permitted at law, we may vary any aspect of the Service at our discretion. If we do so, you may choose to end using the Service with us.  We reserve the right to immediately suspend or terminate the Service and/or these Terms at any time upon notice to you.
                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Content of the App</p>
                <p>
                    Whilst we make every effort to ensure information or content on the App is accurate and complete, we make no express or implied representation or warranty that the information and materials on our App are correct. No express or implied representation or warranty is given that information or materials contained on our App are complete, accurate, up-to-date, fit for a particular purpose and, to the extent permitted by law, we do not accept any liability for any errors or omissions.
                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>App License</p>
                <p>
                    You agree not to (and agree to not enable or procure others to):
                    <br />
                    duplicate, copy, reproduce or distribute the App or any part of the App including any coding of the App, except as necessary to use it on your device;
                    <br />
                    other than as permitted by applicable laws, modify, translate, make derivative works from, disassemble, reverse compile or reverse engineer any part of the App for any purpose, including but not limited to, in order to build a similar or competitive product; or
                    <br />
                    remove, deface or destroy any copyright or other rights notices used on, or in connection with the App, and you acknowledge that any attempt to do so is a violation of these Terms.
                </p>
            </div>

            <p className='p-2 text-center fs-3 fw-bolder my-3'>Terms of the Service</p>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Account Registration</p>
                <p>
                    In order to use the Service, you must create an account and register with us (Account).
                    <br />
                    You must not join nor use the Service on behalf of any third party.
                    <br />
                    You must not authorize or allow any other third party to use the Service and/or your Account on your behalf.
                    <br />
                    Use of the Service is strictly limited to users who are 18 years of age or over. If you are under 18, you are not permitted to use the App, register as a user of the App, or send personal information to us.
                    <br />
                    You must provide a valid email address (Registered Email Address) when you register to become a member and create an Account. The Registered Email Address you provide when you register for your Account must be an active email address in regular use by you.
                    If you want to receive marketing and promotional codes, you must provide a valid mobile phone when you register to become a member and create an Account. The Registered Mobile Phone Number  you provide when you register for your Account must be an active phone number in regular use by you.
                    <br />
                    We may immediately suspend or terminate your registration or your access to the Service (permanently or temporarily) in the event that you breach any of these Terms. We may also impose restrictions on the length and manner of usage of any part of the Service for any reason whatsoever. If restrictions are imposed on you personally, you must not attempt to use the App under any other name or user or on any other mobile device.
                    <br />
                    You must keep your Account password confidential at all times. You are responsible for all transactions made on the Moneta App App using your Account or otherwise on your Account. We are not liable for any loss or damage arising out of the unauthorized use of your Account.
                    <br />
                    You should only open one Moneta App account. If you attempt to open multiple accounts, we may at any time block all of your accounts and cancel all of your points and redemption requests.
                    <br />
                    Moneta App is currently only available in Albania. We do not accept receipts outside Albania at the moment..

                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Rewards and  Promotional Coupons</p>
                <p>
                    The Moneta App ("the App," "our App") serves as your personal finance assistant, designed to offer you deeper insights into your spending habits through scanning and categorizing invoices. By continuing to use the App, you agree to these Terms and Conditions, including both existing and planned functionalities.
                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Current Features</p>
                <p>
                    Invoice Scanning: Our core feature allows you to scan invoices from various transactions. The scanned data will be processed to offer you a detailed view of where your income is going. This allows for better financial planning and management.
                    <br />
                    <br />
                    Expenditure Categorization: The App further categorizes your expenditures into various buckets like "Food," "Entertainment," "Utilities," etc., making it easier for you to understand your spending patterns.
                    <br />
                    <br />
                    Analytics: Based on your uploaded invoices, the App offers analytical tools that help you assess your financial health. You can view your spending over time, compare expenditures across categories, and even set budgets for yourself.

                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Future Features</p>
                <p>
                    Business Connectivity: One of our most anticipated features is the capability to directly link users with businesses they frequently patronize. This functionality will allow for a direct communication channel between consumers and businesses, facilitating easier discovery of new products, services, and promotions.
                    <br />
                    <br />
                    Loyalty Points System: In addition to tracking your spending, the App will offer a loyalty points system that rewards you for uploading invoices from participating businesses. The more invoices you upload, the more points you accumulate.
                    <br />
                    <br />
                    Special Offers and Discounts: As you accumulate points, you will become eligible for various rewards, including but not limited to special offers, discount coupons, and cash-back opportunities directly from businesses. These offers will be personalized based on your spending habits to provide you with rewards that genuinely interest you.
                    <br />
                    <br />
                    Enhanced Personalization: By securely analyzing your spending data, the App will recommend offers and discounts that are closely aligned with your past purchases, ensuring that you get the most value out of the ecosystem we are creating.

                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Important Notices</p>
                <p>
                    *Voluntary Participation: All future features, including the points system and special offers, are optional. You can choose to participate or opt-out at any time.
                    <br />
                    <br />
                    *Data Security: Your scanned invoices and any associated data will be stored securely in compliance with data protection laws and our Privacy Policy.
                    <br />
                    <br />
                    *Terms for Redemption: Specific terms, conditions, and eligibility criteria for redeeming points or offers will be clearly defined and communicated when these features become available.
                    <br />
                    <br />
                    *Right to Modify or Terminate: We reserve the right to change, suspend, or discontinue any features or aspects of the App at any time. Any such changes will be communicated through updates to these Terms and Conditions.
                    <br />
                    <br />
                    By continuing to use the Moneta App, you acknowledge that you have read, understood, and agreed to these Terms and Conditions, including both existing and planned features.
                </p>
            </div>

            <p className='p-2 text-center fs-3 fw-bolder my-3'>Criteria for Receipt Image Quality and Eligibility for Rewards</p>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>QR Code Scanning</p>
                <p>
                    The QR code on the receipt must be scannable by the Moneta App's built-in scanner. If the QR code is unreadable, while you may still manually input the expense into your account, the receipt will not qualify for rewards or points. The responsibility for a readable QR code lies with the business providing you the receipt, not the App. The App is equipped with the best QR code reader on the market.
                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Required Information on Receipts</p>
                <div>
                    <p>To ensure that uploaded receipt images are eligible for rewards, including points, they must be clearly legible and contain the following information:</p>
                    <ul>
                        <li><p>Name of the retailer</p></li>
                        <li><p>List of all purchased items</p></li>
                        <li><p>Individual item prices</p></li>
                        <li><p>Total transaction amount</p></li>
                        <li><p>Date of the transaction</p></li>
                    </ul>
                </div>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Incomplete or Missing Information</p>
                <p>
                    Receipts lacking any of the aforementioned details will be deemed ineligible for earning rewards or points.
                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Timeframe for Uploading</p>
                <p>
                    To be considered for rewards or points, receipts must be uploaded within seven (7) days of the transaction date. Receipts uploaded after this period can still be stored in your spending account but won't qualify for rewards or points.
                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Authenticity of Receipts</p>
                <p>
                    Only original and unaltered receipts should be uploaded. Receipts that have been modified or sourced from individuals outside of your household will not be eligible for rewards, including points.
                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Receipt Ownership</p>
                <p>
                    You can only submit receipts that directly result from your own shopping behaviors. Do not submit receipts that are not from your own shopping trips or that have been changed, modified or received by third parties. Receipts that are not eligible for rewards may include (but are not limited to):
                    <br />
                    <br />
                    receipts obtained from friends, roommates or colleagues;
                    <br />
                    <br />
                    receipts obtained from customers;
                    <br />
                    <br />
                    receipts obtained for commercial or work-related purposes;
                    <br />
                    <br />
                    receipts obtained from strangers, including receipts collected from the bin; or
                    <br />
                    <br />
                    receipts obtained from distant relatives.
                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Suspension/Termination of Account</p>
                <p>
                    We, in our absolute discretion, reserve the right to decide who may use the App.
                    <br />
                    <br />
                    We may at any time and in our absolute discretion freeze or block your Account.
                    <br />
                    <br />
                    We may at any time and in our absolute discretion suspend your Account if fraudulent activity is detected or suspected. Should you have any questions related to your Account or the App, we request that you contact us via email at <Link to="mailto:hello@moneta.al">hello@moneta.al</Link>
                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Fraudulent Activity</p>
                <p>
                    Any fraudulent activity will result in immediate suspension or termination of your Account and reward points.
                    <br />
                    <br />
                    Fraudulent activities include (but are not limited to):

                </p>
                <ul>
                    <li><p>uploading receipts that are not your own;</p></li>
                    <li><p>uploading the same receipt multiple times;</p></li>
                    <li><p>breaking up your shopping purchases to obtain multiple receipts;</p></li>
                    <li><p>answering survey questions with misleading or untruthful information;</p></li>
                    <li><p>creating multiple accounts to gain referral points;</p></li>
                    <li>
                        <p>scanning the wrong barcodes or taking photos of the wrong products for points; or</p>
                        <br />
                        <p>any other activities which we determine are fraudulent or dishonest.</p>
                    </li>
                </ul>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Third Party Websites</p>
                <p>
                    Certain links available via our Service may include materials from third-parties. Third-party links on this site may direct you to third-party websites that are not affiliated with us. We accept no responsibility for the content of any website or mobile application to which a link from the App exists. We are not responsible for examining or evaluating the content or accuracy and we do not expressly or impliedly warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties. Please review carefully the third-party’s policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the relevant third-party.
                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Copyright</p>
                <p>

                    All content and material on the Website, App and Service or sent to you in a Moneta App email is protected by Albanian copyright law, and may also be protected as a trade mark, service mark, or under other rights and laws.
                    <br />
                    Except for the creation of a temporary copy on your computer which is inherent to the process of the normal use of the Website or Service, the content and material referred to above may not be copied, reproduced, published, stored in a retrieval system, altered or otherwise used in any form or by any means, in whole or in part, without our prior written approval, or the approval of the relevant copyright owner.
                    <br />
                    Intellectual Property
                    We are the sole and exclusive owner or the licensee of all intellectual property rights (including patents) in the App, Website, Service and in the material published on any of them. Those works are protected by copyright and trademark laws and treaties around the world. All such rights are reserved.
                    <br />
                    <br />
                    You acknowledge that this Agreement and your use of the App, Website and / or Service does not convey any interest of a proprietary nature, and in particular, does not transfer any interest in any of our intellectual property (or patents).
                    <br />
                    <br />
                    You represent and warrant to us that:
                    <br />
                    <br />
                    You will not take any action that harms or diminishes the value of, and goodwill associated with, our intellectual property; and
                    <br />
                    <br />
                    You will not infringe the intellectual property rights of any third party in uploading materials to the Service or the App.
                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Data Usage and Consent</p>
                <p>
                    By creating an account and utilizing the Moneta App ("the App"), you grant us permission to collect, process, and share your personal data and information ("Data") in alignment with our Privacy Policy. Your Data may be used for research, analytics, and may also be shared with or sold to third-party organizations for related purposes. All actions concerning your Data will be in strict compliance with prevailing data protection and privacy laws.
                    <br />
                    <br />
                    You have the option to opt out of the sharing, sale, or other forms of data dissemination as described in our Privacy Policy. This can be done either through the App's settings or by reaching out to our Privacy Officer at <Link to="mailto:hello@moneta.al">hello@moneta.al</Link>. However, please note that opting out may affect your ability to fully access and participate in our services, including but not limited to our rewards programs.
                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Marketing</p>
                <p>
                    Subject to our compliance with applicable anti-spam laws, by registering an Account and using the App you consent to receiving marketing emails or messages from us from time to time.
                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Limitation of Liability</p>
                <p>
                    To the maximum extent permitted by law, you release us, our related bodies company, our related entities, and any of our respective officers, directors, employees and agents from all claims, demands and damages (actual and consequential) of any kind and nature including (but not limited to) damage such as loss of revenue, unavailability of systems or loss of data, arising out of:
                    <br />
                    Any advertising emails or survey invitation email sent to you by us or on our behalf;
                    <br />
                    <br />
                    Your membership of the Service;
                    <br />
                    <br />
                    Your use of or inability to use the Service;
                    <br />
                    <br />
                    The quality of goods and/or services purchased from third parties by you;
                    <br />
                    <br />
                    Any merchant or product descriptions contained on our Website or contained in advertising email sent to you by us or on our behalf;
                    <br />
                    <br />
                    Any failure of or delay in a merchant to notify us of a Qualifying Transaction; or
                    <br />
                    <br />
                    Any failure or default beyond our reasonable control.
                    <br />
                    <br />
                    You assume all responsibility and risk with respect to your use of the App and you understand and agree that, to the maximum extent permitted by law, we exclude all warranties, conditions, representations and guarantees (express or implied) in relation to the provision of goods and services by us under these Terms or in connection with the Service and the App.
                    <br />
                    Nothing in these Terms operates to allow us to do anything that is against the law, or limit or exclude any liability (or statutory warranties, conditions or guarantees) that cannot be limited or excluded by law or limit any party’s rights under the Australian Consumer Law. To the maximum extent permitted by law, our liability in connection with these Terms and/or the Service for breach of any representation, warranty, guarantee or condition (express or implied) imposed by law that cannot be excluded is limited (at our option) to:

                </p>
                <ul>
                    <li>supplying the services again;</li>
                    <li>paying the cost of having the services supplied again; or
                        <br />
                        if applicable, reversing the applicable rewards redemption.
                    </li>
                </ul>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Copyright Act</p>
                <p>

                    We are committed to respecting the rights of others in regards to their intellectual property and complying with Albanian  copyright law.
                    <br />
                    <br />
                    If you believe that any of the content featured on our Service has infringed on your copyright, please notify us and send us the following information:
                    <br />
                    <br />
                    An electronic signature or physically made signature of the individual authorized to act in the interests of the copyright owner who claims the work that they allege has been infringed.
                    <br />
                    <br />
                    Identify the copyrighted work that has allegedly been infringed, or, if multiple of your works have been infringed, then include a detailed list of what and where these infringements are.
                    <br />
                    <br />
                    Outlining of the specific material that is alleged to have been copied or contains infringing activity that is to be removed or have its access disabled. You must also include information that is accurate enough to permit us to locate the material that is being infringed upon.
                    <br />
                    <br />
                    Provide information that is reasonably accurate to allow us to contact you or the aggrieved party, such as a phone number, email address, home address etc.
                    <br />
                    <br />
                    A statement in good faith that you or the aggrieved party has a genuine belief that the use of the works in the infringing manner outlined does not have the authorisation of the copyright owner, or the law.
                    <br />
                    <br />
                    A statement confirming that the information in the takedown notice is honest, and under risk of perjury, that the issuing party has the authorisation to act on behalf of the copyright owner on cases of work that have been allegedly infringed on.
                    <br />
                    <br />
                    Please send all the relevant documents to <Link to="mailto:hello@moneta.al">hello@moneta.al</Link>.
                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Link to the Privacy Policy</p>
                <p>
                    We respect our user privacy and take our obligation to protect your privacy and personal information seriously. We operate our Service under the PRIVACY POLICY published at https://www.moneta.al/privacy-policy. We strongly recommend you to read this policy now and continue to read it at your convenience as the policy is updated from time to time. By downloading Moneta App and signing up for an Account, you agree that we may collect, use and disclose your information as described in the Privacy Policy and you warrant that all data provided by you is accurate.
                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Indemnity</p>
                <p>
                    You agree to indemnify and hold Moneta App, and each of our related bodies corporate, related entities, and any of our respective officers, directors, employees, agents representatives, licensors, advertisers, suppliers, and operational service providers (Indemnified Persons) harmless against and all losses, claims (including third party claims), demands, damages, liabilities, costs (including legal costs), expenses, penalties or suits incurred by the Indemnified Persons as a result of or consequential upon your use of the App and/or Service and/or any violation of the terms of this Agreement.
                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Termination</p>
                <p>
                    Moneta App may, in its sole discretion at any time, temporarily or permanently suspend your account or access to the Service if we reasonably suspect that you are not complying with the terms of this Agreement. Moneta App reserves the right to otherwise restrict, suspend or terminate your ability to access or use the App if it determines in its sole discretion that you pose a threat to the App or its users or that your conduct is inconsistent with our brand values. Moneta App will not be liable for any cost, expense or damages whatsoever arising out of the termination of this Agreement.
                    <br />
                    <br />
                    If this Agreement is terminated, the parties are released from the obligations to continue to perform under this agreement, except those obligations that expressly or by their nature, survive termination and each party retains the rights and claims it has against the other party for any past breach of this Agreement.
                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>End-user Terms for Users of Apple</p>
                <p>
                    You acknowledge and agree that:
                    <br />
                    This Agreement is concluded between you and Moneta App, not you and Apple, Inc (Apple);
                    <br />
                    <br />
                    Moneta App and its licensors, and not Apple, are solely responsible for the App;
                    <br />
                    <br />
                    Apple has no responsibility whatsoever to furnish any maintenance and support services with respect to the App;
                    <br />
                    <br />
                    In the event of any failure of the App to conform to any applicable warranty, you may notify Apple and Apple will refund the purchase price (if any) you paid for the App;
                    <br />
                    <br />
                    To the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the App, and Apple have no responsibility for any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty;
                    <br />
                    <br />
                    Apple is not responsible for addressing any claims that you or any third party make relating to the App or your possession and/or use of that App including:
                    product liability claims;
                    <br />
                    <br />
                    Any claim that the App fails to conform to any applicable legal or regulatory requirement; and
                    <br />
                    claims arising under consumer protection or similar legislation.
                    <br />
                    <br />
                    Apple will have no responsibility whatsoever for the investigation, defense, settlement or discharge of any third party claim that the App infringes that third party’s intellectual property rights;
                    <br />
                    You will comply with any relevant third party terms of agreement when using the App; and Apple and its subsidiaries are third party beneficiaries of this agreement and, upon your acceptance of this agreement, Apple will have the right (and will be deemed to have accepted the right) as a third party beneficiary to enforce this agreement against you.
                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>You represent and warrant that</p>
                <p>
                    Your use of the App will be in strict accordance with this Agreement and with all applicable laws and regulations, including without limitation any local laws or regulations in your country, state, city, or other governmental area, regarding online conduct and acceptable content;
                    <br />
                    <br />
                    Your use of the App will not infringe or misappropriate the intellectual property rights of any third party;
                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Unforeseeable Circumstance</p>
                <p>
                    In no event will we be liable for any failure to comply with this Agreement to the extent that such failure arises from unforeseeable circumstances or factors outside our reasonable control.
                </p>
            </div>

            <p className='p-2 text-center fs-3 fw-bolder my-3'>Miscellaneous Provisions</p>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Severability</p>
                <p>
                    If any part of these Terms is or becomes illegal, invalid or otherwise unenforceable, then to the extent permissible by law, that term which is found to be illegal, invalid or unenforceable, shall be severed and deleted and the remaining Terms shall survive and remain in full force and effect and continue to be binding and enforceable.If any part of these Terms is or becomes illegal, invalid or otherwise unenforceable, then to the extent permissible by law, that term which is found to be illegal, invalid or unenforceable, shall be severed and deleted and the remaining Terms shall survive and remain in full force and effect and continue to be binding and enforceable.
                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Notice</p>
                <p>
                    You agree that any notice or communication we give to you pursuant to these Terms may be given to the email address we have recorded for you or by posting such notice or communication on our Website (or by push notification on the Service App when that App is released). A notice so given shall be deemed received by the intended recipient on the date the notice is sent or posted (as the case may be).
                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Assignment</p>
                <p>
                    We may assign, novate or transfer in whole or in part any of the rights or obligations under these Terms without your written consent or the consent of any other members (and if we do so we will provide notice on the Website). The Agreement set out in these Terms is personal to you and so you may not assign, novate or sub-licence in whole or part any of your rights or obligations under these Terms without our prior written consent.
                </p>
            </div>

            <div className='d-flex flex-column my-2'>
                <p className='fs-5 fw-bold mb-4'>Governing law</p>
                <p>
                    This Agreement is governed by the law of Albania and you irrevocably and unconditionally submit to the non-exclusive jurisdiction of the courts of Albania.
                    <br />
                    <br />
                    Nothing in this clause:

                </p>
                <ul>
                    <li><p>Prevents us from commencing proceedings in any jurisdiction it considers convenient to enforce its rights and protect its interests; or</p></li>
                    <li><p>Entitles you to object to the jurisdiction of an otherwise competent Court in relation to any such proceedings.</p></li>
                </ul>
            </div>
        </div>
    )
}

export default TermsAndConditions