import "./App.css";
import Body from "./Components/Body/Body";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import TermsAndConditions from "./pages/TermsAndConditions";
import Footer from "./Components/Footer/Footer";
import NotFound from "./pages/NotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Body />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
