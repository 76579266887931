import { Link } from "react-router-dom";
import "./Footer.css";
import Modal from "../common/Modal";
import { useState } from "react";
const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <footer className="footer">
        <span className="text-center my-4">
          &copy; COPYRIGHT{" "}
          <a href="https://gridcoding.com/" target="_blank" rel="noreferrer">
            gridcoding.com
          </a>
        </span>
        <div className="footer-links text-center mb-4">
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-and-conditions">Terms & Conditions</Link>
          <Link onClick={() => setIsOpen((prev) => !prev)}>Data Deletion</Link>
          <Link to="mailto:hello@moneta.al">Email Us</Link>
        </div>
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} />
      </footer>
    </>
  );
};

export default Footer;
